import {
	LOGIN_USER_SUCCESS,
	LOGOUT_USER_SUCCESS,
	SELECT_GROUP,
} from '../../constants/actionTypes';

import { getLoggedInUser } from '../../helpers/shared/auth';

const user = getLoggedInUser();
const INIT_STATE = {
	group: user && user.groups_users && user.groups_users.length > 0 ? user.groups_users[0] : null,
	user: user,
};

const Auth = ( state = INIT_STATE, action ) => {
	let user;
	let group;

	switch ( action.type ) {
		case LOGIN_USER_SUCCESS:
			user = action.payload;
			group = user.groups_users && user.groups_users.length > 0 ? user.groups_users[0] : null;
			return { ...state, group, user };

		case LOGOUT_USER_SUCCESS:
			return { ...state, user: null };

		case SELECT_GROUP:
			return { ...state, group: action.payload };

		default: return { ...state };
	}
};

export default Auth;
