import { CookieWrapper, getCookieDomain } from '../shared-variables';
import jwtDecode from 'jwt-decode';
import { propertyExists } from './api-utils';

/**
 * Returns true if a user is logged in.
 * WARNING: Components referencing this function should use next/dynamic.
 * @param {object} context - Required if calling isLoggedIn() in getInitialProps().
 * @return {bool}
 */
export const isLoggedIn = ( context = null ) => {
	const token = parseToken( getToken( context ) );
	if ( !token ) {
		return false;
	}
	const currentTime = Date.now() / 1000;
	if ( token.exp < currentTime ) {
		return false;
	}
	return true;
};

/**
 * Returns the currently logged in user's token.
 * @param {object} context - Required if calling getToken() in getInitialProps().
 * @return {string}
 */
export const getToken = ( context = null ) => {
	return CookieWrapper.get( 'token', context );
};

/**
 * Decodes a JWT into a user object.
 * @param {string} token
 * @return {object|null}
 */
export const parseToken = ( token ) => {
	if ( !token ) {
		return null;
	}
	return jwtDecode( token );
};

/**
 * Returns the currently logged in user's data.
 * WARNING: Components referencing this function should use next/dynamic.
 * @param {object} context - Required if calling getLoggedInUser() in getInitialProps().
 * @return {object|null}
 */
export const getLoggedInUser = ( context = null ) => {
	if ( !isLoggedIn() ) {
		return null;
	}
	const user = CookieWrapper.get( 'user', context );
	if ( !user ) {
		return null;
	}
	return typeof user === 'string' ? JSON.parse( user ) : user;
};

/**
 * Logs in a user.
 * @param {object} response
 */
export const logIn = ( response ) => {
	if ( response ) {
		const options = getAuthOptions();
		if ( propertyExists( response, 'token' ) ) {
			CookieWrapper.set( 'token', response.token, options );
		}
		if ( propertyExists( response, 'user' ) ) {
			CookieWrapper.set( 'user', response.user, options );
		}
	} else {
		logOut();
	}
};

/**
 * Logs out the current user.
 */
export const logOut = () => {
	const options = getAuthOptions();
	CookieWrapper.remove( 'token', options );
	CookieWrapper.remove( 'user', options );
};

/**
 * Returns the default auth cookie options.
 * @returns {object}
 */
export const getAuthOptions = () => {
	return { path: '/', domain: getCookieDomain() };
};

/**
 * Can the currently logged in user perform an action
 * WARNING: Components referencing this function should use next/dynamic.
 * @param {string} id
 * @returns {boolean}
 */
export const isCurrentUser = ( id ) => {
	const logged_in_user = getLoggedInUser();
	return logged_in_user && logged_in_user.id === id;
};
