import * as serviceWorker from './serviceWorker';
import App from './App';
import { configureStore } from './redux/store';
import dotenv from 'dotenv';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

// Allow routing to work properly in S3.
const history = createBrowserHistory();
const path = ( /#!(\/.*)$/.exec( window.location.hash ) || [] )[1];
if ( path ) {
	history.replace( path );
}

if ( process.env.NODE_ENV === 'development' ) {
	// For Cypress.
	require( 'whatwg-fetch' );
}

dotenv.config( { path: `/.env.${process.env.REACT_APP_ENV}` } );

ReactDOM.render( <Provider store={configureStore()}>
	<App />
</Provider>, document.getElementById( 'root' ) );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
