import { getLoggedInUser, isLoggedIn } from './helpers/shared/auth';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

// lazy load all the views
const Dashboard = React.lazy( () => import( './pages/dashboards/' ) );
const ErrorPage = React.lazy( () => import( './pages/ErrorPage' ) );

// auth
const Login = React.lazy( () => import( './pages/auth/Login' ) );
const Logout = React.lazy( () => import( './pages/auth/Logout' ) );
const ForgotPassword = React.lazy( () => import( './pages/auth/ForgotPassword' ) );
const ResetPassword = React.lazy( () => import( './pages/auth/ResetPassword' ) );
const ConfirmEmail = React.lazy( () => import( './pages/auth/ConfirmEmail' ) );
const UnlockAccount = React.lazy( () => import( './pages/auth/UnlockAccount' ) );

//brands
const BrandForm = React.lazy( () => import( './pages/brands/Form' ) );
const BrandList = React.lazy( () => import( './pages/brands/List' ) );

// users
const ProfileForm = React.lazy( () => import( './pages/profiles/Form' ) );
const ProfileList = React.lazy( () => import( './pages/profiles/List' ) );
const SuperAdminForm = React.lazy( () => import( './pages/super-admins/Form' ) );
const SuperAdminList = React.lazy( () => import( './pages/super-admins/List' ) );
const MyAccount = React.lazy( () => import( './pages/account/Form' ) );
const UserForm = React.lazy( () => import( './pages/users/Form' ) );
const UserList = React.lazy( () => import( './pages/users/List' ) );

// groups
const GroupForm = React.lazy( () => import( './pages/groups/Form' ) );
const GroupList = React.lazy( () => import( './pages/groups/List' ) );

// teams
const TeamForm = React.lazy( () => import( './pages/teams/Form' ) );
const TeamList = React.lazy( () => import( './pages/teams/List' ) );

// awards
const AwardForm = React.lazy( () => import( './pages/awards/Form' ) );
const AwardList = React.lazy( () => import( './pages/awards/List' ) );

// attributes
const AttributeList = React.lazy( () => import( './pages/attributes/List' ) );

// DealTrack
const DealTrackLandingPage = React.lazy( () => import( './pages/dealtrack/Landing' ) );

// exclusive listings
const ExclusiveListingsForm = React.lazy( () => import( './pages/exclusive-listings/Form' ) );
const ExclusiveListingsList = React.lazy( () => import( './pages/exclusive-listings/List' ) );

// offices
const OfficeForm = React.lazy( () => import( './pages/offices/Form' ) );
const OfficeList = React.lazy( () => import( './pages/offices/List' ) );

// stats
const GroupStatForm = React.lazy( () => import( './pages/group-stats/Form' ) );
const GroupStatList = React.lazy( () => import( './pages/group-stats/List' ) );
const UserStatForm = React.lazy( () => import( './pages/user-stats/Form' ) );
const UserStatList = React.lazy( () => import( './pages/user-stats/List' ) );

// invitations
const InvitationForm = React.lazy( () => import( './pages/invitations/Form' ) );
const InvitationList = React.lazy( () => import( './pages/invitations/List' ) );
const InvitationEmail = React.lazy( () => import( './pages/invitations/Email' ) );
const InvitationStats = React.lazy( () => import( './pages/invitations/Stats' ) );
const EmailSignature = React.lazy( () => import( './pages/invitations/EmailSignature' ) );

// home eval settings
const AgentSettingsForm = React.lazy( () => import( './pages/agent-settings/Form' ) );

// query and cost reports for all users
const ReportsForm = React.lazy( () => import( './pages/reports/Form' ) );

// handle auth and authorization

const PrivateRoute = ( { component: Component, roles, ...rest } ) => (
	<Route {...rest} render={props => {
		if ( !isLoggedIn() ) {
			// not logged in so redirect to login page with the return url
			return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		}

		const loggedInUser = getLoggedInUser();
		// check if route is restricted by role
		if ( roles && roles.indexOf( loggedInUser.subtype ) === -1 ) {
			// role not authorised so redirect to home page
			return <Redirect to={{ pathname: '/' }} />;
		}

		// authorised so return component
		return <Component {...props} />;
	}} />
);

PrivateRoute.propTypes = {
	component: PropTypes.object,
	location: PropTypes.object,
	roles: PropTypes.array,
};

const routes = [
	// auth and account
	{ path: '/login', name: 'Login', component: Login, route: Route },
	{ path: '/logout', name: 'Logout', component: Logout, route: Route },
	{ path: '/forgot-password', name: 'Forgot Password', component: ForgotPassword, route: Route },
	{ path: '/reset-password', name: 'Reset Password', component: ResetPassword, route: Route },
	{ path: '/confirm-email', name: 'Confirm Email', component: ConfirmEmail, route: Route },
	{ path: '/unlock-account', name: 'Unlock Account', component: UnlockAccount, route: Route },
	{ path: '/account', name: 'Edit Account', component: MyAccount, route: Route },

	// profiles and users
	{ path: '/profiles', name: 'Profiles', component: ProfileList, route: PrivateRoute, exact: true },
	{ path: '/profiles/new', name: 'New Profile', component: ProfileForm, route: PrivateRoute },
	{ path: '/profiles/:id/edit', name: 'Edit Profile', component: ProfileForm, route: PrivateRoute },
	{ path: '/super-admins', name: 'Super Admins', component: SuperAdminList, route: PrivateRoute, exact: true, roles: ['SuperAdmin'] },
	{ path: '/super-admins/new', name: 'New Super Admin', component: SuperAdminForm, route: PrivateRoute, roles: ['SuperAdmin'] },
	{ path: '/super-admins/:id/edit', name: 'Edit Super Admin', component: SuperAdminForm, route: PrivateRoute, roles: ['SuperAdmin'] },
	{ path: '/users', name: 'Users', component: UserList, route: PrivateRoute, exact: true, roles: ['SuperAdmin', 'Admin'] },
	{ path: '/users/new', name: 'New User', component: UserForm, route: PrivateRoute, roles: ['SuperAdmin', 'Admin'] },
	{ path: '/users/:id/edit', name: 'Edit User', component: UserForm, route: PrivateRoute, roles: ['SuperAdmin', 'Admin'] },

	//brands
	{ path: '/brands', name: 'Brands', component: BrandList, route: PrivateRoute, exact: true, roles: ['SuperAdmin'] },
	{ path: '/brands/new', name: 'Brands', component: BrandList, route: PrivateRoute, roles: ['SuperAdmin'] },
	{ path: '/brands/:id/edit', name: 'Edit Brand', component: BrandForm, route: PrivateRoute, roles: ['SuperAdmin'] },
	// groups
	{ path: '/brokerages', name: 'Brokerages', component: GroupList, route: PrivateRoute, exact: true },
	{ path: '/brokerages/new', name: 'New Brokerage', component: GroupForm, route: PrivateRoute, roles: ['SuperAdmin'] },
	{ path: '/brokerages/:id/edit', name: 'Edit Brokerage', component: GroupForm, route: PrivateRoute },
	{ path: '/sub-brokerages', name: 'Sub-Brokerages', component: GroupList, route: PrivateRoute, exact: true },
	{ path: '/sub-brokerages/new', name: 'New Sub-Brokerage', component: GroupForm, route: PrivateRoute },
	{ path: '/sub-brokerages/:id/edit', name: 'Edit Sub-Brokerage', component: GroupForm, route: PrivateRoute },

	// teams
	{ path: '/teams', name: 'Teams', component: TeamList, route: PrivateRoute, exact: true },
	{ path: '/teams/new', name: 'New Team', component: TeamForm, route: PrivateRoute },
	{ path: '/teams/:id/edit', name: 'Edit Team', component: TeamForm, route: PrivateRoute },

	// awards
	{ path: '/awards', name: 'Awards', component: AwardList, route: PrivateRoute, exact: true },
	{ path: '/awards/new', name: 'New Award', component: AwardForm, route: PrivateRoute },
	{ path: '/awards/:id/edit', name: 'Edit Award', component: AwardForm, route: PrivateRoute },

	// profile attributes
	{ path: '/profile-attributes', name: 'Attributes', component: AttributeList, route: PrivateRoute },

	{ path: '/dealtrack', name: 'DealTrack', component: DealTrackLandingPage, route: PrivateRoute },

	// exclusive listings
	{ path: '/exclusive-listings', name: 'Exclusive Listings', component: ExclusiveListingsList, route: PrivateRoute, exact: true },
	{ path: '/exclusive-listings/new', name: 'New Exclusive Listing', component: ExclusiveListingsForm, route: PrivateRoute },
	{ path: '/exclusive-listings/:id/edit', name: 'Edit Exclusive Listing', component: ExclusiveListingsForm, route: PrivateRoute },

	// offices
	{ path: '/offices', name: 'Offices', component: OfficeList, route: PrivateRoute, exact: true },
	{ path: '/offices/new', name: 'New Office', component: OfficeForm, route: PrivateRoute },
	{ path: '/offices/:id/edit', name: 'Edit Office', component: OfficeForm, route: PrivateRoute },

	// stats
	{ path: '/group-stats', name: 'Stats', component: GroupStatList, route: PrivateRoute, exact: true },
	{ path: '/group-stats/new', name: 'New Stat', component: GroupStatForm, route: PrivateRoute },
	{ path: '/group-stats/:id/edit', name: 'Edit Stat', component: GroupStatForm, route: PrivateRoute },
	{ path: '/user-stats', name: 'Stats', component: UserStatList, route: PrivateRoute, exact: true },
	{ path: '/user-stats/new', name: 'New Stat', component: UserStatForm, route: PrivateRoute },
	{ path: '/user-stats/:id/edit', name: 'Edit Stat', component: UserStatForm, route: PrivateRoute },

	// invitations
	{ path: '/invitations', name: 'Invitations', component: InvitationList, route: PrivateRoute, exact: true },
	{ path: '/invitations/new', name: 'New Invitation', component: InvitationForm, route: PrivateRoute, exact: true },
	{ path: '/invitations/:id/edit', name: 'Edit Invitation', component: InvitationForm, route: PrivateRoute, exact: true },
	{ path: '/invitations/:id/email', name: 'Send Email', component: InvitationEmail, route: PrivateRoute },
	{ path: '/invitations/:id/stats', name: 'View Stats', component: InvitationStats, route: PrivateRoute },
	{ path: '/invitations/email-signature', name: 'Email Signature', component: EmailSignature, route: PrivateRoute, exact: true },

	// home eval settings
	{ path: '/agent-settings', name: 'Settings', component: AgentSettingsForm, route: PrivateRoute, exact: true },

	{ path: '/reports', name: 'Reports', component: ReportsForm, route: PrivateRoute, exact: true },

	// other pages
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard, route: PrivateRoute },
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/dashboard" />, // eslint-disable-line react/display-name
		route: PrivateRoute,
	},
	{
		path: '/force-error',
		exact: true,
		component: () => { throw new Error( 'Oops!' ); }, // eslint-disable-line react/display-name
		route: PrivateRoute,
	},
	{ name: 'Not Found', component: ErrorPage, route: Route },
];

export { routes, PrivateRoute };
