import './assets/scss/DefaultTheme.scss';
import React, { Component, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLoggedIn } from './helpers/shared/auth';
import Loadable from 'react-loadable';
import { routes } from './routes';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable( {
	loader: () => import( './components/NonAuthLayout' ),
	render( loaded, props ) {
		let Component = loaded.default;
		return <Component {...props} />;
	},
	loading,
} );

const AuthLayout = Loadable( {
	loader: () => import( './components/AuthLayout' ),
	render( loaded, props ) {
		let Component = loaded.default;
		return <Component {...props} />;
	},
	loading,
} );

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = ( WrappedComponent ) => {
	const HOC = class extends Component {
		displayName() {
			return 'HOC';
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return connect()( HOC );
};

/**
 * Main app component
 */
class App extends Component {
	/**
	 * Returns the layout component based on different properties
	 * @param {*} props
	 */
	getLayout = () => {
		return isLoggedIn() ? AuthLayout : NonAuthLayout;
	}

	render() {
		return (
			// rendering the router with layout
			<BrowserRouter>
				<Switch>
					{routes.map( ( route ) => {
						return (
							<route.route
								key={route.path || route.name}
								path={route.path}
								exact={route.exact}
								roles={route.roles}
								component={withLayout( props => {
									const Layout = this.getLayout();
									return (
										<Suspense fallback={loading()}>
											<Layout {...props}>
												<route.component {...props} key={Date.now()} />
											</Layout>
										</Suspense>
									);
								} )}
							/>
						);
					} )}
				</Switch>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = ( state ) => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
	};
};

export default connect( mapStateToProps, null )( App );
