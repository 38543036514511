export const environment = {
	development: {
		REACT_APP_API_URL: 'http://localhost:3001',
		REACT_APP_COOKIE_DOMAIN: 'localhost',
		REACT_APP_FRONTEND_URL: 'http://localhost:3003',
		REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: '',
	},
	staging: {
		REACT_APP_API_URL: 'https://api.stage.realintro.com',
		REACT_APP_COOKIE_DOMAIN: '.stage.realintro.com',
		REACT_APP_FRONTEND_URL: 'https://stage.realintro.com',
		REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: 'UA-155951171-2',
	},
	production: {
		REACT_APP_API_URL: 'https://realintro.com/api',
		REACT_APP_COOKIE_DOMAIN: '.realintro.com',
		REACT_APP_FRONTEND_URL: 'https://realintro.com',
		REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID: 'UA-155951171-2',
	},
};
