import { Cookies } from 'react-cookie';
import { environment } from '../constants/environmentVariables';

export const getApiDomain = () => {
	const env = environment[process.env.REACT_APP_ENV];
	return env.REACT_APP_API_URL;
};

export const getCookieDomain = () => {
	const env = environment[process.env.REACT_APP_ENV];
	return env.REACT_APP_COOKIE_DOMAIN;
};

export class CookieWrapper {
	/**
	 * Gets a cookie's value.
	 * @param {string} name - Required.
	 * @return {*}
	 */
	static get( name ) {
		return new Cookies().get( name );
	}

	/**
	 * Sets a cookie's value.
	 * @param {string} name - Required.
	 * @param {*} value - Required.
	 * @param {object} options
	 */
	static set( name, value, options = null ) {
		new Cookies().set( name, value, options );
	}

	/**
	 * Removes a cookie.
	 * @param {string} name - Required.
	 * @param {object} options
	 */
	static remove( name, options = null ) {
		new Cookies().remove( name, options );
	}
}
