import set from 'set-value';

/**
 * Replaces empty strings with nulls to satisfy API requirements.
 * @param {object} object
 */
export const convertEmptyStringToNull = ( object ) => {
	Object.keys( object ).forEach( key => {
		if ( object[key] === '' ) {
			set( object, key, null );
		} else if ( isObject( object[key] ) ) {
			convertEmptyStringToNull( object[key] );
		}
	} );
};

/**
 * Returns true if this object is empty.
 * @param {object} object
 * @return {boolean}
 */
export const isEmptyObject = ( object ) => {
	return Object.keys( object ).length <= 0;
};

/**
 * Returns true if this is an object.
 * @param {*} value
 * @return {boolean}
 */
export const isObject = ( value ) => {
	return !!value && typeof value === 'object' && !Array.isArray( value );
};

/**
 * Replaces `hasOwnProperty` but avoids no-prototype-builtins eslint error.
 * @param {object} object
 * @param {string} property
 */
export const propertyExists = ( object, property ) => {
	return Object.prototype.hasOwnProperty.call( object, property );
};

/**
 * Removes empty items from an array.
 * @param {array[object]} records
 */
export const removeEmptyItems = ( items ) => {
	let output = [];

	items.forEach( i => {
		removeEmptyKeys( i );
		if ( !isEmptyObject( i ) ) {
			output.push( i );
		}
	} );

	return output;
};

/**
 * Removes empty objects from an object.
 * @param {object} object
 */
export const removeEmptyKeys = ( object ) => {
	if ( !isObject( object ) ) {
		return;
	}

	Object.keys( object ).forEach( key => {
		removeEmptyKeys( object[key] );

		if ( isObject( object[key] ) && isEmptyObject( object[key] ) ) {
			delete object[key];
		}
	} );
};
